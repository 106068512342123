export default {
    status: {
        save_success: 'Data saved successfully',
        save_error: 'Error saving data',
        sms_sent: 'SMS message with verification code sent',
        sent_error: 'Error sending verification code',
        loading_error: 'Error loading data',
        data_copied: 'Data copied',
        password_restore_request_success: 'A password recovery email has been sent to your email',
        password_restore_request_error: 'Error sending password reset email',
        payment_remove_success: 'Payment system "{name}" disabled',
        payment_remove_error: 'Payment system "{name}" disconnection error',
        webhook_added: 'Webhook added successfully',
        webhook_removed: 'Webhook removed successfully',
        webhook_added_error: 'Error adding Webhook',
        webhook_removed_error: 'Webhook removal failed',
        integration_launched: 'Integration launched successfully',
        integration_stopped: 'Integration stopped successfully',
        integration_launched_error: 'Integration launch failed',
        integration_stopped_error: 'Integration stopped error',
        source_archived: 'Source added to archive',
        source_recovered: 'Source restored from archive',
        source_archived_error: 'Error archiving source',
        source_recovered_error: 'Error while restoring source',
        source_removed: 'Source removed',
        source_removed_error: 'Error removing source',
        source_added: 'Source added',
        season_removed: 'Season removed',
        season_removed_error: 'Error removing season',
        balance_redirect_error: 'Error redirecting to payment page',
        user_is_locked: 'User {user} is locked',
        user_is_locked_error: 'User locked error: {user}',
        user_is_unlocked: 'User {user} has been unlocked',
        user_is_unlocked_error: 'Error restoring user: {user}',
        user_invited: 'Invitation Sent',
        user_invited_error: 'Error sending invitation',
        email_confirmation_canceled: 'New Email Confirmation Canceled',
        metro_loading_error: 'Error loading metro station list',
        district_loading_error: 'Error loading list of districts',
        season_remove_success: '"{title}" tab removed',
        season_remove_error: 'Error removing tab "{title}"',
        photo_remove_error: 'Error when deleting a photo',
        archived_success: 'Object "{name}" added to archive',
        restored_success: 'Object "{name}" restored from archive',
        archived_error: 'Error adding object "{name}" to archive',
        restored_error: 'Error while restoring object "{name}" from archive',
        data_update_error: 'Data update failed',
        photo_upload_error: 'Error uploading file: {name}'
    },
    statuses : {
        booked : 'Booked',
        request : 'Request',
        canceled : 'Canceled',
        deleted : 'Deleted',
        additional_is_delete: '[deleted]',
    },
    left_side: {
        calendar : 'Calendar',
        clients_and_reservations: 'Clients and reservations',
        partners : 'Partners',
        channel_manager : 'Channel manager',
        settings : 'Settings',
        faq : 'Knowledge base',
        black_list : 'Black list',
        news_and_wishes : 'News and wishes',
        statistics : 'Statistics',
    },
    form: {
        login: 'Login',
        phone_or_email: 'Phone or email',
        password: 'Password',
        remembe_me: 'Password',
        login_button: 'Login',
        registration: 'Registration',
        forgot_password: 'Forgot your password?',
        no_sms: 'Didn\'t get the SMS confirmation code?',
        no_email: 'Did you not receive an email with instructions for confirming the email address?',
        unknown_error: 'Unknown error',
    },
    navbar : {
        cart : 'Cart',
        add : 'Add',
        add_apartment : 'Add Apartment',
        add_expense : 'Add Expense',
        settings : 'Settings',
        logout : 'Logout',
        logout_confirm : 'Logout?',
        return_to_old_chessmate: 'Return to old chessmate',
        login_by_manager: 'Login by manager',
        work_time: '(from 8am to 8pm)',
        write_to_support: 'Write to technical support',
    },
    messages_short_list : {
        no_messages : 'No messages.',
        new_count : 'no new messages | {count} new message',
        unread_count : 'All messages | All messages ({count} unread',
    },
    messages_full_list : {
        title : 'All messages',
        read_all : 'Set read all',
        close_modal : 'Close',
    },
    cart : {
        title : 'Send by email and SMS',
        title_link : 'Link to options',
        history_load : 'History',
        history_table_date : 'Date',
        history_table_phone : 'Phone',
        history_table_email : 'Email',
        history_table_source : 'Source',
        history_table_more : 'more',
        get_link : 'Get link',
        send : 'Send',
        close : 'Close',
        copy : 'Copy',
        clear : 'Clear cart',
        arrival : 'Arrival',
        departure : 'Departure',
        email : 'Email',
        sms : 'SMS',
        buy_more : 'Buy more',
        sms_left: 'SMS left: {count}',
        lifetime: 'Link lifetime',
        lifetime_days: 'Unlimited | 1 day | {count} days',
        amount: '@:agencyCurrency per day',
    },
    expense : {
        title : 'New expense',
        date : 'Date',
        sum : 'Amount',
        category : 'Category of expense',
        category_placeholder : 'Show more categories',
        add_category : 'Title of new category',
        comment : 'Comment',
        apartments : 'Apartments',
        apartments_placeholder : 'Search by title',
        apartments_select_all : 'Total agency expenses',
        cancel : 'Close',
        save : 'Add expense',
        saving : 'Adding expense...',
        new_category_name : 'Category name',
    },
    date_picker : {
        default: 'Not set',
        invalid_time: 'Wrong time format',
    },
    date_picker_simple : {
        default: 'Not set',
    },
    tags: {
        tags_list: 'List of tags',
        title: 'Available tags:',
        close: 'Close',
        guest_name: 'Guest name',
        checkin_date: 'Check in date',
        checkout_date: 'Checkout date',
        address: 'Object address',
        address_eng: 'Object address (transliteration)',
        how_to_get: 'How to get there (configured in the object card)',
        manager_name: 'Name of the check-in manager (configurable in the property card)',
        manager_name_eng: 'Accommodation manager name (transliteration)',
        manager_phone: 'Phone of the accommodation manager (configurable in the property card)',
        agency_name: 'Agency name',
        agency_phone: 'Agency phone',
        agency_email: 'Agency email',
        links_booking: 'Link for prepayment',
        links_booking_eng: 'Link for prepayment (english page)',
        wait_time: 'Payment due date',
        is_airbnb: 'Display content for Airbnb bookings only',
        is_booking_com: 'Display content only for bookings from Booking.com',
        is_ostrovok: 'Display content only for reservations from Ostrovok.ru',
        is_oktogo: 'Display content only for bookings from Oktogo.ru',
        is_mention_prepaid: 'Display content for prepaid bookings only',
        is_agency_prepaid_settings: 'Show content only if agency has payments set up'
    },
    attributes : {
        default : 'Any',
        reset : 'Reset all attributes',
    },
    modal_seasons: {
        title_edit: 'Season Edit',
        title_add: 'Adding a season',
        name: 'Name',
        season_start: 'Season start date',
        season_end: 'Season end date',
        add: 'Add',
        added: 'Added...',
        edit: 'Edit',
        edited: 'Edited...',
        close: 'Close'
    },
    drop_zone: {
        drag_files: 'Drag files',
        to_temp: 'to selection',
        choose_files: 'Choose files',
        or_drag: 'or drag them to the selection',
        max_size: 'Max file size: {count}Mb',
        types: 'Allowed types:',
        loaded: 'Images uploaded: {from} from {to}',
    },
    alert_modal: {
        cancel: 'Cancel',
        confirm: 'Confirm'
    },
    time_picker: {
        reset: 'Reset'
    },
    text_editor: {
        heading: 'Heading {number}',
        save: 'Save',
        add_link: 'Add link: ',
        visit_url: 'Go to link:',
        edit: 'Edit',
        remove: 'Remove'
    },
    filezone: {
        label: 'Select Files',
        max_count: 'Max files: {count}'
    },
    days: {
        today: 'today',
        tomorrow: 'tomorrow',
        yesterday: 'yesterday',
    },
    unknown_error: 'Unknown error, please try again',
    connection_error: 'No internet signal',
    timeout_error: 'Request timed out, try again',
    hide_password: 'Hide password',
    show_password: 'Show password',
    custom_timer: 'You can resend the code after {time}',
}
