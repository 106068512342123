import commonStore from '@/common/commonStore';
import stringHelper from '@/common/helpers/stringHelper';
import {i18n} from '@/common/init';
import sentry from '@/common/sentry';

const isAuthPage = process.env.VUE_APP_PUBLIC_PATH === '/auth/';

const CONTENT_TYPES = {
  JSON: 'application/json',
  FORM_DATA: 'multipart/form-data',
};

const ACTIONS_ON_REDIRECT = {
  ABORT: 'abort',
  ALERT: 'alert',
}

const DEFAULT_TRACK_TIME_MS = 15000;
const DEFAULT_TIMEOUT = 15000;

const send = async (options) => {
  const params = {
    method: options.method,
    headers: {
      'X-Locale': i18n.global.locale.value,
    },
  };

  if (!options.notAuth) {
    params.headers['X-User-Token'] = localStorage.getItem('token');
  }

  const safeUrl = options.url.replace(/\W+/g, '');
  let trackTimeMore = options.trackTimeMore ?? DEFAULT_TRACK_TIME_MS;
  let contentType = options.contentType ?? CONTENT_TYPES.JSON;

  const encodeValue = (key, value) => {
    if (options.notEncodeKeys && options.notEncodeKeys.indexOf(key) > -1) {
      return value;
    } else {
      return encodeURIComponent(value);
    }
  };

  let url = options.url;

  if (url.indexOf('/v2/') === 0) {
    url = url.replace('/v2/', '');
  }

  if (typeof options['query'] !== 'undefined' && Object.keys(options.query).length > 0) {
    const query = [];
    for (let key in options.query || {}) {
      if (options.query[key] === '' || options.query[key] === null) {
        continue;
      }
      if (options.query[key].constructor === Array) {
        options.query[key].forEach(param => {
          query.push(encodeURIComponent(key) + '[]=' + encodeValue(key, param));
        });
      } else if (typeof options.query[key] === 'object') {
        for (let k in options.query[key]) {
          if (options.query[key][k] === '' || options.query[key][k] === null) {
            continue;
          }
          query.push(encodeURIComponent(key + '[' + k + ']') + '=' + encodeValue(key, options.query[key][k]));
        }
      } else {
        query.push(encodeURIComponent(key) + '=' + encodeValue(key, options.query[key]));
      }
    }
    if (query.length) {
      url += (url.indexOf('?') > -1 ? '&' : '?') + query.join('&');
    }
  }

  if (typeof options.data !== 'undefined') {
    if (options.data instanceof FormData) {
      params.body = options.data;
      contentType = CONTENT_TYPES.FORM_DATA;
    } else if (typeof options.data === 'object') {
      if (contentType === CONTENT_TYPES.FORM_DATA) {
        let formData = new FormData();
        for (let key in options.data) {
          formData.append(key, options.data[key]);
        }
        params.body = formData;
      } else {
        params.body = JSON.stringify(options.data);
      }
    }
  }

  for (let id in commonStore.state.apiRequests) {
    if (commonStore.state.apiRequests[id].key === options.key) {
      if (commonStore.state.apiRequests[id].timeout) {
        clearTimeout(commonStore.state.apiRequests[id].timeout);
      }
      commonStore.state.apiRequests[id].abort();
    }
  }

  const requestId = stringHelper.getRandomUUID();

  if (isAuthPage) {
    options.timeout ??= DEFAULT_TIMEOUT;
  }

  const currentRequest = commonStore.state.apiRequests[requestId] = {
    key: options.key ?? null,
    timeout: options.timeout
        ? setTimeout(() => currentRequest.abort({isTimeout: true}), options.timeout)
        : null,
    abortController: new AbortController(),
    onRedirect: options.onRedirect ?? ACTIONS_ON_REDIRECT.ALERT,
    abort: function (reason) {
      this.abortController.abort(reason);
    }
  };

  params.signal = currentRequest.abortController.signal;

  if (options.useLoading && options.key) {
    commonStore.commit('createLoading', options.key);
  }

  if (options.headers) {
    if (options.needMergeHeaders) {
      params.headers = {...params.headers, ...options.headers};
    } else {
      params.headers = options.headers;
    }
  }

  if (contentType !== CONTENT_TYPES.FORM_DATA) {
    params.headers['Content-Type'] = contentType;
  }

  if (url.indexOf('https://') !== 0) {
    url = process.env.VUE_APP_API_URL + url
  }

  if (options.noHeaders) {
    params.headers = {}
  }

  try {
    if (!navigator.onLine && isAuthPage) throw { name: 'NoConnection' };
    const timeStart = new Date().getTime();

    const response = await fetch(url, params);

    const timeStop = new Date().getTime();
    const timeElapsed = timeStop - timeStart;
    if (timeElapsed > trackTimeMore) {
      sentry.track(`api:${safeUrl}:time_${timeElapsed}`);
    }

    if (response.ok) {
      let data;
        if (options?.responseType === 'blob') {
          data = await response.blob();
        } else if (options?.responseType === 'text') {
          data = await response.text();
        } else {
          data = await response.json();
        }

        return Promise.resolve(data);
    } else {
      // при логине все ошибки валидации приходят со статусом 401
      if (response.status === 401 && !options.notAuth) {
        // TODO: window.location.href.includes('v2_sign_up') временное решение до перехода на полную новую авторизацию
        window.location.href = commonStore.getters.hasFeatureFlag('v2_sign_up') || window.location.href.includes('v2_sign_up')
            ? process.env.VUE_APP_AUTH_URL
            : process.env.VUE_APP_LOGIN_URL;
      }

      if (response.status === 422 || response.status === 401) {
        const data = await response.json();
        throw {errors: data.errors, isValidation: true};
      } else if (response.status === 404) {
        throw {name: 'NotFound'};
      } else if (`${response.status}`.startsWith('5')) {
        sentry.track(`api:${safeUrl}:${response.status}`);
        throw {name: 'ServerError'};
      } else {
        throw {name: 'UnknownError'}
      }
    }
  } catch (error) {
    let err;
    if (error.isValidation) {
      err = error;
    } else if (error.isTimeout) {
      err = {
        ...error,
        errors: {
          common: [i18n.global.t('timeout_error')]
        }};
    } else if (error.name){
      const name = error.name;
      if (name === 'AbortError') {
        err = {isAbort: true};
      } else if (name === 'SyntaxError') {
        err = {isSyntax: true};
      } else if (name === 'ParseError') {
        err = {isParse: true};
      } else if (name === 'ServerError') {
        err = {
          isServer: true,
          errors: {
            common: [i18n.global.t('unknown_error')]
          }
        };
      } else if (name === 'NoConnection' && isAuthPage) {
        err = {
          isConnection: true,
          errors: {
            common: [i18n.global.t('connection_error')]
          }
        }
      } else if (name === 'NotFound') {
        err = {isNotFound: true};
      } else {
        err = {isUnknown: true};
      }
    }
    return Promise.reject(err);
  } finally {
    if (options.useLoading && options.key) {
      commonStore.commit('removeLoading', options.key);
    }
    delete commonStore.state.apiRequests[requestId];
  }
};

const get = async (param) => {
  if (typeof param === 'string') {
    return await send({
      url: param,
      method: 'GET',
    });
  }
  return await send({
    ...param,
    method: 'GET',
  });
};

const post = async (param) => {
  if (typeof param === 'string') {
    return await send({
      url: param,
      method: 'POST',
    });
  }
  return await send({
    ...param,
    method: 'POST',
  });
};

const postNotAuth = async (param) => {
  return await send({
    ...param,
    method: 'POST',
    notAuth: true,
  });
};

const getNotAuth = async (param) => {
  return await send({
    ...param,
    method: 'GET',
    notAuth: true,
  });
};

const patch = async (param) => {
  if (typeof param === 'string') {
    return await send({
      url: param,
      method: 'PATCH',
    });
  }
  return await send({
    ...param,
    method: 'PATCH',
  });
};

const put = async (param) => {
  if (typeof param === 'string') {
    return await send({
      url: param,
      method: 'PUT',
    });
  }
  return await send({
    ...param,
    method: 'PUT',
  });
};

const del = async (param) => {
  if (typeof param === 'string') {
    return await send({
      url: param,
      method: 'DELETE',
    });
  }
  return await send({
    ...param,
    method: 'DELETE',
  });
};

const abortRequestByKey = (key) => {
  for (let id in commonStore.state.apiRequests) {
    if (commonStore.state.apiRequests[id].key === key) {
      commonStore.state.apiRequests[id].abort();
    }
  }
};

const abortRequestOnRouterChange = () => {
  for (let id in commonStore.state.apiRequests) {
    if (commonStore.state.apiRequests[id].onRedirect === ACTIONS_ON_REDIRECT.ABORT) {
      commonStore.state.apiRequests[id].abort();
    }
  }
}

const abort = (data) => {
  if (typeof data === 'string') {
    abortRequestByKey(data);
  } else {
    data.forEach(item => abortRequestByKey(item));
  }
};

export default {
  get,
  post,
  postNotAuth,
  getNotAuth,
  put,
  patch,
  del,
  abort,
  abortRequestOnRouterChange,
  send,
  CONTENT_TYPES,
  ACTIONS_ON_REDIRECT,
};
