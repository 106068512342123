import useToast from '@/common/api/useToast';
const {show} =  useToast()

const copyText = (text, toastText) => {
  navigator.clipboard.writeText(text)
  if (toastText) {
    show({text: toastText})
  }
}

const formatCurrency = (number, options = {}) => {
  return new Intl.NumberFormat('ru-RU', {...options}).format(number);
}

// Пустое поле в редакторе всегда <p><br></p>
const isEditorEmptyString = (string) => {
  return string === '<p><br></p>'
    || string === '<p>\n</p>' // ловил при сохранении на v1 и просмотре на v2
    || !string
}

const getRandomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const getRandomUUID = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

const getNestedValue = (model, keys) => keys.reduce((acc, item) => acc[item], model);


const getStringWithLink = (str) => {
    const target = str.includes('http') ? '_blank' : '_self'
    return str.replace(/(\()(.*?)(\)\[)(.*?)(\])/g, `<a href='$2' target='${target}' class='link text-decoration-none'>$4</a>`)
}

const getIsEmail = (string) => {
    return string.includes('@');
}

const getDigitPhone = (phone) => {
    return phone.replace(/\D/g, '');
}

export default {
  copyText,
  formatCurrency,
  isEditorEmptyString,
  getRandomString,
  getRandomUUID,
  getNestedValue,
  getStringWithLink,
  getDigitPhone,
  getIsEmail,
}
